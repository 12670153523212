
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <form class="needs-validation" @submit.prevent="Form">
              <div class="row justify-content-md-center mt-4">
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label class="d-inline align-items-center">
                          แนบเอกสาร:</label
                        >
                        <b-form-file
                          accept=".xlsx, .xls"
                          type="file"
                          ref="img"
                          id="img"
                          v-model="tooltipform.files"
                          class="form-control"
                          plain
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.files.$error,
                          }"
                        ></b-form-file>
                        <div
                          v-if="submitform && $v.tooltipform.files.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.files.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 text-end">
                      <button type="submit" class="btn btn-success">
                        บันทึก
                      </button>
                    </div>
                    <!-- <div class="row float-end"></div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.impPpcus,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },

  data() {
    return {
      loading: undefined,
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      title: appConfig.impPpcus,
      items: [
        {
          text: "นำเข้าข้อมูล",
          active: true,
        },
        {
          text: appConfig.impPpcus,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      tooltipform: { files: null },

      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      salaryLabel: "",
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      submitform: false,
      filter: {
        ppCusCode: "",
        nameTh: "",
        branchId: "",
        familyNameTh: "",
        cusSrcId: "",
        occId: "",
        status: "",
        salary: "",
      },
      occOption: [],
      statusOption: [
        { nameTh: "กำลังติดตาม", id: "A" },
        { nameTh: "เสร็จสิ้น", id: "C" },
        { nameTh: "Lost", id: "L" },
      ],
      cusSrcOption: [],
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      statusLabel: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "ppCusCode",
          label: "รหัสลูกค้ามุ่งหวัง",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "familyNameTh",
          label: "นามสกุล",
          sortable: true,
        },
        {
          key: "cusSrcNameTh",
          label: "แหล่งที่มา",
          sortable: true,
        },

        {
          key: "occNameTh",
          label: "อาชีพ",
          sortable: true,
        },
        {
          key: "level",
          label: "ความสนใจ",
          sortable: true,
        },
        {
          key: "salary",
          label: "รายได้",
          sortable: true,
        },

        {
          key: "status",
          label: "สถานะ",
          sortable: true,
        },
        {
          key: "branchNameTh",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },
  validations: {
    tooltipform: {
      files: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // Set the initial number of items
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    print(Data) {
      this.data = Data;
      this.$router.push({
        name: "add-contactJob",
        params: {
          data: this.data.item,
        },
      });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [this.branchIdSearch.branchId];
      }

      this.getData();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.tooltipform.$invalid != true) {
        this.importFile();
      }
    },

    importFile: function () {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.tooltipform.files);

      useNetw
        .post("api/dealer-import-prospect-customer/store", formData)
        .then((response) => {
          this.tooltipform.files = null;
          this.submitform = false;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    deleteParts: function (rowData) {
      this.partData = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/prospect-customer/delete", {
          data: {
            ppCusId: this.partData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
